//
// Copyright 2019-2025 Luxembourg Institute of Science and Technology (LIST - http://www.list.lu/).
//
// Author: Olivier Parisot (olivier.parisot@list.lu)
//

import React, { Component } from 'react';
import './App.css';

import {isMobile} from 'react-device-detect';

//import { geolocated } from "react-geolocated";

import { CSVLink } from "react-csv";

import { Map, TileLayer, Marker, CircleMarker, Tooltip } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import { UncontrolledTooltip, Container, Row, Col, Button, ButtonGroup, Modal, ModalHeader, ModalFooter, Input } from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'bootstrap/dist/css/bootstrap.min.css';

import moment from 'moment';

import {point, featureCollection, nearestPoint} from '@turf/turf';

import UserGuideModalBody from './UserGuideModalBody.js';
import ReferencesModalBody from './ReferencesModalBody.js';
import DisclaimerModalBody from './DisclaimerModalBody.js';
import PrivacyModalBody from './PrivacyModalBody.js';

import TimeSeriesChart from './TimeSeriesChart.js';
import Histogram from './Histogram.js';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import shiftHelper from './shiftHelper.js';

import contentHelper from './contentHelper.js';

import Control from 'react-leaflet-control';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faCrosshairs, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { faSignOutAlt, faInfoCircle, faSync, faDownload } from '@fortawesome/free-solid-svg-icons';


import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/css/react-flags-select.css';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { registerLocale, setDefaultLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';
import de from 'date-fns/locale/de';
import lb from 'date-fns/locale/lb';
import en from 'date-fns/locale/en-GB';

import HeatmapLayer from 'react-leaflet-heatmap-layer';

import axios from 'axios';

import bcrypt from 'bcryptjs';

import L from 'leaflet';
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});


registerLocale('en', en)
registerLocale('fr', fr)
registerLocale('lb', lb)
registerLocale('de', de)

const saltRounds=10;

const getStringAsMoment = (sdate) =>
{
  if(sdate === '')
    return moment();
  const arrr=sdate.split('/');
  if (arrr.length!==3) return moment().add(1000,'years');
  else return moment([Number(arrr[2]),Number(arrr[1])-1,Number(arrr[0])]);
}

const SHOW_HEATMAP=window.location.href.includes("heatmap");

const TEMP_FIELD="temperatures";
const PRECIPITATIONS_FIELD="precipitations";

const centerLatitude=49.762;
const centerLongitude=6.11;
const defaultZoom=9.6;

const defaultCultivar="Kerubino";
const defaultCultivarSusceptibilityRank=5;

const defaultSowingDate='15/10/2024';

const defaultGrowthStage=50;
const defaultGrowthStageDate='1/6/2024';
const defaultFictiveSowingDate=shiftHelper.getFictiveSowingDate(getStringAsMoment(defaultGrowthStageDate),defaultGrowthStage);


console.info("URL: "+window.location.href);
const mobileMode=window.innerWidth<1024 || isMobile || window.location.href.includes("mobile");
console.info("Mobile mode: "+mobileMode);
const debugMode=false;
console.info("Debug mode: "+debugMode);
const appName=mobileMode?"ShIFT-Mobile":"ShIFT";
console.info("AppName: "+appName);

const logoDiv=
        (<span>
        <img style={{display: "inline-block", width: "150px"}} alt="ShiFT logo" src={process.env.PUBLIC_URL+'/shift_logo-little.png'} />
        &nbsp;&nbsp;
        <a className="App-link" rel="noopener noreferrer" target="_blank" href="http://www.list.lu/">
          <img style={{display: "inline-block", width: "150px"}} alt="LIST logo" src={process.env.PUBLIC_URL+'/list_logo.png'} />
        </a>
        </span>);




/**
 * App.
 *
 * Author: Olivier Parisot
 */
class App extends Component
{
  /** Map reference. */
  map;

  constructor(props)
  {
    super(props);

    this.state =
    {
      lang: contentHelper.defaultLang(),
      errorMsg: null,
      loading: false,
      weatherData: null,
      mailleId: null,
      weatherDataAsFeatureCollection: null,
      charSeries: null,
      sowingDateAsMoment: getStringAsMoment(defaultSowingDate),
      growthStageDateAsMoment: getStringAsMoment(defaultGrowthStageDate),
      lastFungicideSprayDate: '',
      lastFungicideSprayDateString: 'NONE',
      cultivarSusceptibilityRank: defaultCultivarSusceptibilityRank,
      cultivar: defaultCultivar,
      cultivarsList: {},
      latitude: centerLatitude,
      longitude: centerLongitude,
      zoom: defaultZoom,
      zoomSnap: 0.1,
      showUserGuide: false,
      showReferences: false,
      showDisclaimer: false,
      showPrivacy: false,
      growingStage: defaultGrowthStage,
      fictiveSowingDate: defaultFictiveSowingDate,
      growthStageEstimatedBySowingDate: true,
      userName: null,
      userNameTmp: null,
      hashTmp: null,
      csvReport: null
    };

    this.onMapClick=this.onMapClick.bind(this);

  }


  getHeader = () =>
  {
    return (
      <span>
        {logoDiv}
        {debugMode?"[D]":""}
        {debugMode&&mobileMode?"[M]":""}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {this.getLangSelect()}
      </span>
    );
  }

  getDefaultCountry = () =>
  {
    //console.info("getDefaultCountry()");
    if (this.state.lang===contentHelper.en()) return "GB";
    else if (this.state.lang===contentHelper.fr()) return "FR";
    else if (this.state.lang===contentHelper.de()) return "DE";
    else if (this.state.lang===contentHelper.lu()) return "LU";
  }

  getFooter = () =>
  {
    return (
    <footer className="App-footer">
      <br/>
      <hr/>
      &copy;Copyright 2019-2025 Luxembourg Institute of Science and Technology
      (<a className="App-link" rel="noopener noreferrer" target="_blank" href="http://www.list.lu/">LIST</a>)
      <br/>
      <a className="App-link" rel="noopener noreferrer" target="_blank" href="mailto:marco.beyer@list.lu">
        <span role="img" aria-label="">📧 {contentHelper.feedbackMessage(this.state.lang)} 📧</span>
      </a>
      <br/>
      <br/>
    </footer>);
  }

  getLangSelect = () =>
  {
    return (
      <ReactFlagsSelect
         key={"react-flags-select-"+Math.floor(Math.random()*1001)}
         countries={["GB", "FR", "DE", "LU"]}
         placeholder="Language"
         defaultCountry={
          this.getDefaultCountry()
         }
         selectedSize={20}
         optionsSize={18}
         showOptionLabel={false}
         showSelectedLabel={false}
         onSelect={(countryCode)=>{
           //console.info(countryCode);
           if (countryCode==="GB") {this.setState({lang: contentHelper.en()});setDefaultLocale('en');}
           else if (countryCode==="FR") {this.setState({lang: contentHelper.fr()});setDefaultLocale('fr');}
           else if (countryCode==="DE") {this.setState({lang: contentHelper.de()});setDefaultLocale('de');}
           else if (countryCode==="LU") {this.setState({lang: contentHelper.lu()});setDefaultLocale('lb');}
         }}
      />
    );
  }

  onMapClick = (e) =>
  {
      this.setState({mailleId: null, latitude: e.latlng.lat, longitude: e.latlng.lng},()=>
        {
          this.updateNearestMailleId();
        }
      );
  }

  currentSowingYear = (stateObj) =>
  {
    if (this.state.growthStageEstimatedBySowingDate) return stateObj.sowingDateAsMoment.year();
    else return stateObj.fictiveSowingDate.year();
  }

  componentDidMount= () =>
  {
    this.loadWeatherData(this.currentSowingYear(this.state));
    this.loadCultivars(this.currentSowingYear(this.state));
  }

  updateNearestMailleId = () =>
  {
    //console.info("updateNearestMailleId()");
    const targetPoint=point([this.state.latitude,this.state.longitude],{});
    const nearestP=nearestPoint(targetPoint,this.state.weatherDataAsFeatureCollection);
    //toast.info("Nearest "+nearestP.properties.mailleId+" ("+nearestP.properties.distanceToPoint+")!", {position: toast.POSITION.TOP_LEFT});
    this.setState({mailleId: nearestP.properties.mailleId});
    //return {mailleId: nearestP.properties.mailleId, distance: Math.round(nearestP.properties.distanceToPoint)};
  }

  buildHeatmap = () =>
  {
    var heatmaps=[];
    if (SHOW_HEATMAP&&this.state.weatherData!==null)
    {
      heatmaps.push(
        <HeatmapLayer
          key={"heatmap"}
          points={this.state.weatherData.array}
          longitudeExtractor={m => m.longitude}
          latitudeExtractor={m => m.latitude}
          intensityExtractor={m => this.isRedZone(m.mailleId)?500:0.1}
          radius={40}
          blur={40}
          gradient={{0: 'green', 0.2: 'yellow', 0.4: 'orange', 0.6: 'red'}}
        />
      );
    }
    return heatmaps;
  }

  buildStationsMarkers = () =>
  {
    //console.info("buildStationsMarkers() "+this.state.mailleId);
    if (mobileMode) return;
    var markers=[];
    if (this.state.weatherData!==null)
    {
      const array0=this.state.weatherData.array;
      for (var i=0;i<array0.length;i++)
      {
          const isSelected=(i===this.state.mailleId);
          markers.push(
            <CircleMarker
                  key={"circleStation_"+i+"_"+this.state.mailleId}
                  color={isSelected?"blue":"black"}
                  fill={true}
                  center={[array0[i].latitude,array0[i].longitude]}
                  radius={isSelected?8:4} >
               <Tooltip>{array0[i].station}</Tooltip>
            </CircleMarker>
          );
      }
    }
    return markers;
  }

  componentDidUpdate = (prevProps, prevState, snapsho) =>
  {
      if (this.state.userName!==null && this.map!==null) this.map.leafletElement.invalidateSize();
      //if (this.map!==null) this.map.leafletElement.invalidateSize();
      if (this.currentSowingYear(prevState)!==this.currentSowingYear(this.state))
      {
        console.info("should change weather data and cultivars list because sowing year -> "+this.currentSowingYear(this.state))
        // TODO: replace weatherData by a map with the year as key
        this.loadWeatherData(this.currentSowingYear(this.state));
        this.loadCultivars(this.currentSowingYear(this.state));
      }
  }

  localizeMe = () =>
  {
    if (this.props.coords!==null)
    {
      //console.info(this.props.coords);
      this.setState({mailleId: null, latitude: this.props.coords.latitude, longitude: this.props.coords.longitude},()=>{this.updateNearestMailleId()});
    }
  }

  loadWeatherData = (sowingYear) =>
  {
    if (sowingYear<2018 || sowingYear>moment().year())
    {
      console.log("Not load weather data for sowingYear="+sowingYear);
      return;
    }
    console.info("loadWeatherData() "+sowingYear);
    this.setState({loading: true, weatherData: null, weatherDataAsFeatureCollection: null});
    const harvestYear=sowingYear+1;
    const jsonFn="/data/weather/weather-agrimeteo-"+sowingYear+"-"+harvestYear+".json";
    fetch(jsonFn)
      .then(r =>
      {
        console.info("Last update of weather data: "+r.headers.get('last-modified'));
        return r.json();
      })
      .then(data =>
      {
        var maillesPointsArray=[];
        var chartSeries=[];
        for (var i=0;i<data.array.length;i++)
        {
          //console.info(data.array[i]);
          data.array[i].mailleId=i;
          if (data.array[i].latitude!==null)
          {
            maillesPointsArray.push(point([data.array[i].latitude,data.array[i].longitude],{mailleId:i}));
            if (SHOW_HEATMAP) chartSeries.push(this.buildChartSeries(data,i));
          }
        }
        const maillesAsFeatureCollection=featureCollection(maillesPointsArray);
        //toast.info("Loaded "+maillesPointsArray.length+" stations from "+jsonFn+" !", {position: toast.POSITION.TOP_LEFT});
        this.setState({weatherData: data, weatherDataAsFeatureCollection: maillesAsFeatureCollection, chartSeries: chartSeries, loading: false});
        this.updateNearestMailleId();
        this.updateNearestMailleId();
        this.setState({csvReport: this.buildSprayBarCSVReport()});
      })
      .catch(error =>
      {
        const errMsg="Impossible to open "+jsonFn+" !";
        this.setState({loading: false, errorMsg: errMsg});
        toast.error(errMsg, {position: toast.POSITION.TOP_LEFT});
      });
  }

  loadCultivars = (sowingYear) =>
  {
    console.info("loadCultivars() for "+sowingYear);
    this.setState({loading: true, cultivarsList: {}});
    const harvestYear=sowingYear+1;
    const jsonFn="/data/cultivars-"+sowingYear+"-"+harvestYear+".json";
    fetch(jsonFn)
      .then(r =>
      {
        return r.json();
      })
      .then(data =>
      {
        this.setState({cultivarsList: data, cultivar: defaultCultivar, cultivarSusceptibilityRank: defaultCultivarSusceptibilityRank, loading: false});
      })
      .catch(error =>
      {
        const errMsg="Impossible to open "+jsonFn+" !";
        this.setState({loading: false, errorMsg: errMsg});
        toast.error(errMsg, {position: toast.POSITION.TOP_LEFT});
      });
  }

  updateBarsForHeatmap = () =>
  {

  }

  getGrowthStage = (currTSAsMoment) =>
  {
    if (this.state.growthStageEstimatedBySowingDate)
    {
      return shiftHelper.estimateGrowthStageFromSowingDate(this.state.sowingDateAsMoment,currTSAsMoment);
    }
    else
    {
      //console.info(this.state.fictiveSowingDate.format());
      return shiftHelper.estimateGrowthStageFromSowingDate(this.state.fictiveSowingDate,currTSAsMoment);
    }
  }

  acceptTimestampForChart = (currTSAsMoment) =>
  {
    const sowingDateAsMoment=this.state.sowingDateAsMoment;
    if (currTSAsMoment.isBefore(sowingDateAsMoment)) return false;
    if (currTSAsMoment.year()!==sowingDateAsMoment.year()+1) return false;
    if (currTSAsMoment.month()+1<3) return false;
    if (currTSAsMoment.month()+1<4 && currTSAsMoment.date()<15) return false;
    if (currTSAsMoment.month()+1>=8) return false;
    return true;
  }

  isRedZone = (mId) =>
  {
    //console.info("isRedZone "+mId);
    const sprayBarArray=this.state.chartSeries[mId].sprayBar;
    for (var ii=0;ii<sprayBarArray.length;ii++)
    {
        if (moment(sprayBarArray[ii].time).isBefore(moment())) continue;
        //if (!shiftHelper.isDuringGrowingPeriod(moment(sprayBarArray[ii].time))) continue;

        //if (shiftHelper.isSprayBarSuspect(sprayBarArray[ii].value)) return true;

        if (this.tooltipOfBar(sprayBarArray[ii])===contentHelper.emergencyText(this.state.lang)) return true;
    }
    return false;
  }

  buildChartSeries = (wData,mId) =>
  {
      //console.info("buildChartSeries() "+mId);

      var chartSeries={};
      chartSeries.temperatures=[];
      chartSeries.precipitations=[];
      chartSeries.growingStage=[];
      chartSeries.sprayBar=[];

      //const mailleId=this.state.mailleId;
      if (wData!==null&&mId!==null)
      {
        const array0=wData.array[mId];

        var currBar={value: 0, time: null, endtime: null, cnt: 0};
        for (var iiii=0;iiii<array0.timestamps.length;iiii++)
        {
          const currTS=array0.timestamps[iiii];
          const currTSAsMoment=moment(currTS);
          //console.info(array0.timestamps[iiii]+" -> "+currTSAsMoment.format());
          if (!this.acceptTimestampForChart(currTSAsMoment)) continue;

          const nextTSAsMoment=moment(array0.timestamps[iiii+1]);
          const isLastTS=!this.acceptTimestampForChart(nextTSAsMoment);

          currBar.cnt++;
          if (currBar.time===null) currBar.time=currTS;
          if (currBar.cnt%shiftHelper.hoursBarWidth===0)
          {
            currBar.endTime=currTS;
            chartSeries.sprayBar.push(currBar);
            currBar={value: 0, time: null, cnt: 0};
          }
          if (isLastTS)
          {
            currBar.endTime=currTS;
            chartSeries.sprayBar.push(currBar);
          }
        }

        var previousRoundedTemp=null;
        var previousRoundedPrecip=null;
        var previousRoundedGrowingStage=null;
        for (var i=0;i<array0.timestamps.length;i++)
        {
          const currTS=array0.timestamps[i];
          const currTSAsMoment=moment(currTS);
          if (!this.acceptTimestampForChart(currTSAsMoment)) continue;

          const nextTSAsMoment=moment(array0.timestamps[i+1]);
          const isLastTS=!this.acceptTimestampForChart(nextTSAsMoment);

          const growingStage=this.getGrowthStage(currTSAsMoment);
          const temp=array0[TEMP_FIELD][i];
          const precip=array0[PRECIPITATIONS_FIELD][i];
          const isSuspectTS=shiftHelper.isSuspectTempAndPrec(temp,precip);

          const currRoundedTemp=Math.round(temp);
          if (isLastTS||previousRoundedTemp===null||previousRoundedTemp!==currRoundedTemp||isSuspectTS)
          {
            chartSeries.temperatures.push({isSuspectTS: isSuspectTS, value: currRoundedTemp, time: currTS});
            previousRoundedTemp=currRoundedTemp;
          }

          const currRoundedPrecip=/*Math.round(precip)*/precip;
          if (isLastTS||previousRoundedPrecip===null||previousRoundedPrecip!==currRoundedPrecip||isSuspectTS)
          {
            chartSeries.precipitations.push({isSuspectTS: isSuspectTS, value: currRoundedPrecip, time: currTS});
            previousRoundedPrecip=currRoundedPrecip;
          }

          const currRoundedGrowingStage=Math.round(growingStage);
          if (isLastTS||previousRoundedGrowingStage===null||previousRoundedGrowingStage!==currRoundedGrowingStage||isSuspectTS)
          {
            chartSeries.growingStage.push({value: currRoundedGrowingStage, time: currTS});
            previousRoundedGrowingStage=growingStage;
          }

          if (isSuspectTS)
          {
            const csr=this.state.cultivarSusceptibilityRank;
            const timeShiftInHoursVal=shiftHelper.timeShiftInHours(temp,10-csr);
            const tsWithShift=currTS+timeShiftInHoursVal*60*60*1000;
            var idxOfShift=-1;
            for (var ii=0;ii<chartSeries.sprayBar.length;ii++)
            {
              if (tsWithShift>=chartSeries.sprayBar[ii].time&&tsWithShift<=chartSeries.sprayBar[ii].endTime)
              {
                idxOfShift=ii;
                break;
              }
            }
            if (idxOfShift>=0) chartSeries.sprayBar[idxOfShift].value++;
            //else console.info("idxOfShift not found!");
          }
        }

        /*console.info("---------------");
        console.info("temperatures "+chartSeries.temperatures.length+"/"+array0.timestamps.length);
        console.info("precipitations "+chartSeries.precipitations.length+"/"+array0.timestamps.length);
        console.info("growingStage "+chartSeries.growingStage.length+"/"+array0.timestamps.length);
        console.info("sprayBar "+chartSeries.sprayBar.length+"/"+array0.timestamps.length);
        console.info("---------------");*/
      }

      return chartSeries;
  }

  nearestStationName = () =>
  {
    const mailleId=this.state.mailleId;
    console.info(mailleId);
    if (this.state.weatherData!==null&&mailleId!==null)
    {
      return this.state.weatherData.array[mailleId].station;
    }
    else return "";
  }

  getStationsNames = () =>
  {
    var list=[];
    if (this.state.weatherData!==null)
    {
      const array0=this.state.weatherData.array;
      for (var i=0;i<array0.length;i++)
      {
          list.push(array0[i].station);
      }
    }
    else console.warn("No weather data, impossible to get stations names");
    return list;
  }

  getStationsOptions = () =>
  {
    var list=[];
    if (this.state.weatherData!==null)
    {
      const array0=this.state.weatherData.array;
      for (var i=0;i<array0.length;i++)
      {
          list.push(<option key={i} value={array0[i].station}>{array0[i].station}</option>);
          //console.info([array0[i].longitude,array0[i].latitude]);
      }
    }
    else console.warn("No weather data, impossible to get stations names");
    //console.info(list);
    return list;
  }

  getFungiProtectionStartAndEnd = () =>
  {
    if(this.state.lastFungicideSprayDate === '') {
      return [];
    }
    const fungiDate=getStringAsMoment(this.state.lastFungicideSprayDate);
    const fungiDateLimit=getStringAsMoment(this.state.lastFungicideSprayDate).add(22,'days');
    //console.info(fungiDate.format('Do MMM')+" "+fungiDateLimit.format('Do MMM'));
    return [fungiDate,fungiDateLimit];
  }

  tooltipOfBar = (entry) =>
  {
    const currMomentTime=moment(entry.time);

    const growingStage=this.getGrowthStage(currMomentTime);
    if (!shiftHelper.isDuringGrowingPeriod(growingStage)) return contentHelper.sprayForbiddenText(this.state.lang);

    const isSuspect=shiftHelper.isSprayBarSuspect(entry.value);

    const fungiStartEnd=this.getFungiProtectionStartAndEnd();
    if (isSuspect && fungiStartEnd.length > 0 &&
        currMomentTime.isAfter(fungiStartEnd[0])&&
        currMomentTime.isBefore(fungiStartEnd[1]))
    {
      return contentHelper.protectedByFungicideText(this.state.lang);
    }

    return isSuspect?contentHelper.emergencyText(this.state.lang):contentHelper.nothingToDoText(this.state.lang);
  }

  getButtonsGroup = () =>
  {
    return (
      <div style={{textAlign: "center"}}>
      &nbsp;
      <ButtonGroup>
      <Button className="App-button" outline size="sm" onClick={()=>{this.setState({showReferences: false, showUserGuide: true, showDisclaimer: false, showPrivacy: false})}}>
        <small>{contentHelper.userGuideLabel(this.state.lang)}</small>
      </Button>
      &nbsp;
      <Button className="App-button" outline size="sm" onClick={()=>{this.setState({showReferences: true, showUserGuide: false, showDisclaimer: false, showPrivacy: false})}}>
        <small>{contentHelper.referencesLabel(this.state.lang)}</small>
      </Button>
      </ButtonGroup>
      &nbsp;
      <ButtonGroup>
      <Button className="App-button" outline size="sm" onClick={()=>{this.setState({showReferences: false, showUserGuide: false, showDisclaimer: true, showPrivacy: false})}}>
        <small>{contentHelper.disclaimerLabel(this.state.lang)}</small>
      </Button>
      &nbsp;
      <Button className="App-button" outline size="sm" onClick={()=>{this.setState({showReferences: false, showUserGuide: false, showDisclaimer: false, showPrivacy: true})}}>
        <small>{contentHelper.privacyLabel(this.state.lang)}</small>
      </Button>
      </ButtonGroup>
      </div>
    );
  }

  getModals = () =>
  {
    return (
      <div>
      <Modal id="userguide" centered={true} size="lg" fade={false} isOpen={this.state.showUserGuide} toggle={()=>{this.setState({showUserGuide: false})}}>
        <ModalHeader>
          {this.getHeader()}
        </ModalHeader>
        <UserGuideModalBody lang={this.state.lang} />
        <ModalFooter>
          <Button className="App-button"  outline size="sm" onClick={()=>{this.setState({showUserGuide: false})}}>Ok</Button>
        </ModalFooter>
      </Modal>

      <Modal id="references" centered={true} size="lg" fade={false} isOpen={this.state.showReferences} toggle={()=>{this.setState({showReferences: false})}}>
        <ModalHeader>
          {this.getHeader()}
        </ModalHeader>
        <ReferencesModalBody lang={this.state.lang} />
        <ModalFooter>
          <Button className="App-button"  outline size="sm" onClick={()=>{this.setState({showReferences: false})}}>OK</Button>
        </ModalFooter>
      </Modal>

      <Modal id="disclaimer" scrollable={true} centered={true} size="lg" fade={false} isOpen={this.state.showDisclaimer} toggle={()=>{this.setState({showDisclaimer: false})}}>
        <ModalHeader>
          {this.getHeader()}
        </ModalHeader>
        <DisclaimerModalBody lang={this.state.lang} />
        <ModalFooter>
          <Button className="App-button" outline size="sm" onClick={()=>{this.setState({showDisclaimer: false})}}>OK</Button>
        </ModalFooter>
      </Modal>

      <Modal id="privacy" scrollable={true} centered={true} size="lg" fade={false} isOpen={this.state.showPrivacy} toggle={()=>{this.setState({showPrivacy: false})}}>
        <ModalHeader>
          {this.getHeader()}
        </ModalHeader>
        <PrivacyModalBody lang={this.state.lang} />
        <ModalFooter>
          <Button className="App-button" outline size="sm" onClick={()=>{this.setState({showPrivacy: false})}}>OK</Button>
        </ModalFooter>
      </Modal>
      </div>
    );
  }

  checkLoginResponse = (response) =>
  {
    if (response.status===200)
    {
      console.info("Token: "+response.data.access_token); // validty could be checked regularly
      this.setState({userName: this.state.userNameTmp, hashTmp: null, showUserGuide: false});
    }
    else
    {
      this.notifyBadCredentials();
    }
  }

  notifyBadCredentials = () =>
  {
    this.setState({userName: null});
    toast.error("Bad credentials", {position: toast.POSITION.TOP_LEFT});
  }

  getSusceptibilityRankByCultivar = (cultivar) =>
  {
    const ele=this.state.cultivarsList.filter(
        function(data) { return data.cultivar === cultivar }
    );
    //console.info(ele[0]);
    const r=parseInt(ele[0].rank);
    return r;
  }

  getCultivarsOptions = () =>
  {
    var list=[];
    for (var iii=0;iii<this.state.cultivarsList.length;iii++)
    {
      const ccc=this.state.cultivarsList[iii];
      list.push(<option key={ccc.cultivar} value={ccc.cultivar}>{ccc.cultivar+" ("+this.getSusceptibilityRankByCultivar(ccc.cultivar)+")"}</option>);
    }
    return list;
  }

  tryLogin = () =>
  {
    if (this.state.userNameTmp === null || this.state.userNameTmp === "" || this.state.hashTmp === null || this.state.hashTmp === "")
    {
      this.notifyBadCredentials();
      return;
    }

    if (debugMode === false)
    {
      console.info("Send login call to server");
      axios.post('https://shift.list.lu:443/auth/token', {
          username: this.state.userNameTmp,
          hash: this.state.hashTmp
        })
        .then(this.checkLoginResponse)
        .catch(error => {console.log(error);this.notifyBadCredentials();})
    }
    else
    {
      console.info("Debug mode: Skip login call to server");
      const fakeResp={};
      fakeResp.status=200;
      fakeResp.data={};
      fakeResp.data.access_token="feur";
      this.checkLoginResponse(fakeResp);
    }
  }

  buildSprayBarCSVReport = () =>
  {
    let res=[];
    if (this.state.weatherData==null) return res;
    const array0=this.state.weatherData.array;
    for (var i=0;i<array0.length;i++)
    {
      const cs=this.buildChartSeries(this.state.weatherData,i);
      let sb=cs.sprayBar;

      let ts=array0[i].timestamps;
      let prec=array0[i].precipitations;
      let tp=array0[i].temperatures;

      var missingDays="";
      for (var jj=0;jj<ts.length;jj++)
      {
        const tsjj=moment(ts[jj]);
        if (!this.acceptTimestampForChart(tsjj)) continue;
        if (shiftHelper.isProbablyMissingTempAndPrec(tp[jj],prec[jj]))
        {
          if (!missingDays.includes(tsjj.format('D/M'))) missingDays=missingDays+tsjj.format('D/M')+" ";
        }
      }

      for (var ii=0;ii<sb.length;ii++)
      {
        // var missingValue=false;
        // var missingText="";
        // var dddate=moment(sb[ii].time);
        // while (dddate.isBefore(moment(sb[ii].endTime)))
        // {
        //   const fdate=dddate.format('D/M');
        //   if (missingDays.includes(fdate))
        //   {
        //     missingValue=true;
        //     if (!missingText.includes(fdate)) missingText=missingText+fdate+" ";
        //   }
        //   dddate.add(1, 'days');
        // }

        res.push({"station":this.state.weatherData.array[i].station,
                    "longitude":this.state.weatherData.array[i].longitude,
                    "latitude":this.state.weatherData.array[i].latitude,
                    "missingDays": (ii===sb.length-1)?missingDays:"",
                    //"missingWeatherData": missingValue,
                    //"missingWeatherDataDetails": missingText,
                    "barValue": sb[ii].value,
                    "from":moment(sb[ii].time).format('DD/MM/YYYY HH:mm:ss'),
                    "to":moment(sb[ii].endTime).format('DD/MM/YYYY HH:mm:ss')});
      }
    }
    //console.info(res);
    return res;
  }

  getWarningMessage = () =>
  {
    const currYear=moment().format('YYYY');
    if (moment().isBefore(currYear+'-03-15')) return contentHelper.warningMessage(this.state.lang,appName);
    if (moment().isAfter(currYear+'-10-05')) return contentHelper.warningMessage(this.state.lang,appName);
    else return "";
  }

  render = () =>
  {
    const chartSeries=this.buildChartSeries(this.state.weatherData,this.state.mailleId);
    //const chartSeries=(this.state.chartSeries!==null&&this.state.mailleId!=null)?this.state.chartSeries[this.state.mailleId]:this.buildChartSeries(null,null);

    //console.info(chartSeries.temperatures);

    const grayColor="rgba(225,225,225,0.4)";

    const colorOfBar = (entry) =>
    {
      const currMomentTime=moment(entry.time);

      const growingStage=this.getGrowthStage(currMomentTime);
      if (!shiftHelper.isDuringGrowingPeriod(growingStage)) return grayColor;

      const isSuspect=shiftHelper.isSprayBarSuspect(entry.value);

      const fungiStartEnd=this.getFungiProtectionStartAndEnd();
      if (isSuspect && fungiStartEnd.length > 0 &&
          currMomentTime.isAfter(fungiStartEnd[0])&&
          currMomentTime.isBefore(fungiStartEnd[1]))
      {
        return "rgba(191,255,0,0.9)";
      }

      return isSuspect?"red":"rgba(0,255,0,0.5)";
    };

    const colorOfTS = (entry) =>
    {
      const growingStage=this.getGrowthStage(moment(entry.time));
      if (!shiftHelper.isDuringGrowingPeriod(growingStage)) return grayColor;
      return entry.isSuspectTS?"orange":"rgba(0,255,0,0.1)";
    };

    const plotHeight=this.state.growthStageEstimatedBySowingDate?window.innerHeight/7.2:window.innerHeight/7.5;
    //console.info(plotHeight);

    if (this.state.userName === null)
    {
      return (
        <div className="App">
          <ToastContainer />
          {this.getHeader()}
          <br/>
          <br/>
          <b>{appName} Login</b>
          <br/>
          <br/>
          <b>
          <label style={{width: "320px", color: "orange"}}>
          {this.getWarningMessage()}
          </label>
          </b>
          <br/>
          <br/>
          <form className="form-login">
            <div>
              <label id="loginLabel" style={{margin: "10px"}}>
                Identifier:
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <input name="login" type="text"
                        style={{textAlign: "left"}}
                        defaultValue={""}
                        onChange={ (e) => this.setState({userNameTmp: e.target.value}) }
                />
              </label>
            </div>
            <div>
              <label id="pwdLabel" style={{margin: "10px"}}>
                Password:
                &nbsp;&nbsp;
                <input name="password" type="password"
                        style={{textAlign: "left"}}
                        defaultValue={""}
                        onChange={ (e) => {
                                              const raw=e.target.value;
                                              this.setState({hashTmp: bcrypt.hashSync(raw,saltRounds)});
                                          }
                                 }
                />
              </label>
            </div>
          </form>

          <br/>

          <div>
            <Button className="App-button" outline color="primary" size="sm" onClick={ () => this.tryLogin() }><small>Connect</small></Button>
          </div>
          <br/>
          <br/>
          {this.getButtonsGroup()}
          {this.getModals()}
          {this.getFooter()}
        </div>
      );
    }

    console.info(this.state.latitude+" "+this.state.longitude)

    return (
      <div className="App">

        <ToastContainer />

        <section style={{margin: '0 auto', height: '100%', width: '100%', padding: '0'}}>

        <Container style={{height: '100%', width: '100%', padding: '0'}}>

          <Row style={{margin: '0', height: '100%', width: '100%', padding: '0'}}>
            <Col style={{margin: '0', padding: '0'}}>
              <br/>
              <Map style={{margin: '0', minHeight: mobileMode?"0px":(window.innerHeight*0.92)+"px", width: mobileMode?"0%":"100%"}}
                    id='map'
                    ref={map => {this.map = map;}}
                    onClick={this.onMapClick}
                    center={[centerLatitude, centerLongitude]}
                    doubleClickZoom={false}
                    zoomControl={true}
                    zoomAnimation={false}
                    scrollWheelZoom={false}
                    trackResize={true}
                    preferCanvas={true}
                    dragging={true}
                    zoomSnap={this.state.zoomSnap}
                    zoom={this.state.zoom}>
                {this.buildHeatmap()}
                <TileLayer url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"  />
                <Marker position={[this.state.latitude, this.state.longitude]}/>
                {this.buildStationsMarkers()}

                <Control position="bottomleft" >
                  <Button outline style={{width: "40px"}} color="secondary" size="xs" onClick={ () => {if (this.map!==null) this.map.leafletElement.setView(new L.LatLng(centerLatitude,centerLongitude),defaultZoom);} }>
                    <FontAwesomeIcon size="1x" icon={faSync} />
                  </Button>
                </Control>

                <Control position="topright" >
                  <Button outline style={{width: "40px"}} color="secondary" size="xs">
                    <CSVLink filename={"risk-mapping-export-"+(1+this.currentSowingYear(this.state)).toString()+".csv"} data={this.state.csvReport}><FontAwesomeIcon size="1x" icon={faDownload} /></CSVLink>
                  </Button>
                </Control>

              </Map>
            </Col>
            <Col style={{margin: '0', padding: '0'}}>
              {this.getHeader()}
              <span>
                <small>
                <b>
                {this.state.userName !== null? this.state.userName : ""}
                </b>
                &nbsp;&nbsp;
                </small>
                <Button outline style={{width: "40px"}} color="secondary" size="xs" onClick={ () => this.setState({userName: null}) }>
                  <FontAwesomeIcon size="1x" icon={faSignOutAlt} />
                </Button>
              </span>
              <div style={{height: "10px"}}><br/></div>
              <form className="form-basic">

                <div className="form-row" style={{display: mobileMode?"none":"block"}}>
                  <label style={{margin: "0px"}}>
                    {contentHelper.nearestStationLabel(this.state.lang)}
                    <FontAwesomeIcon id="nearestWeatherStationLabel" size="1x" icon={faInfoCircle} />
                    <span style={{textAlign: 'left', width: "180px"}}>{this.nearestStationName()}</span>
                  </label>
                  <UncontrolledTooltip placement="bottom" target="nearestWeatherStationLabel">
                    {contentHelper.weatherStationDesc(this.state.lang)}
                  </UncontrolledTooltip>
                </div>

                <div className="form-row" style={{display: mobileMode?"block":"none"}}>
                  <label>
                    {contentHelper.nearestStationLabel(this.state.lang)}
                    <FontAwesomeIcon id="stationLabel" size="1x" icon={faInfoCircle} />
                    &nbsp;&nbsp;
                    <select value={this.nearestStationName()} name="station" size="1"
                            onChange={(event)=> {console.info(event.target.value);this.setState({mailleId: this.getStationsNames().indexOf(event.target.value) });}      }>
                        {this.getStationsOptions()}
                    </select>
                  </label>
                  <UncontrolledTooltip placement="bottom" target="stationLabel">
                    {contentHelper.weatherStationDesc(this.state.lang)}
                  </UncontrolledTooltip>
                </div>

                <div className="form-row" style={{display: "block"}}>
                  <label style={{margin: "0px"}}>
                    {contentHelper.plantGrowthStageLabel(this.state.lang)}
                    <FontAwesomeIcon id="plantStateLabel" size="1x" icon={faInfoCircle} />
                    <span style={{width: "180px"}}>
                      &nbsp;&nbsp;
                      <Input type="checkbox"
                              checked={this.state.growthStageEstimatedBySowingDate}
                              onChange={e => this.setState({ growthStageEstimatedBySowingDate: e.target.checked})}
                      />
                      <span style={{textAlign: 'left',width: "150px",height:""}}>{contentHelper.estimatedWithSowingDateLabel(this.state.lang)}</span>
                    </span>
                  </label>
                  <UncontrolledTooltip placement="bottom" target="plantStateLabel">
                    {contentHelper.growthStageDesc(this.state.lang)}
                  </UncontrolledTooltip>
                </div>

                <div className="form-row" style={{display: this.state.growthStageEstimatedBySowingDate?"block":"none"}}>
                  <label>
                    {contentHelper.dateOfSowingLabel(this.state.lang)}
                    {/*}<input name="sowingDate" type="text"
                            style={{textAlign: "center"}}
                            defaultValue={this.state.sowingDateAsMoment.format('DD/MM/YYYY')}
                            onChange={(event)=>this.setState({sowingDateAsMoment: getDateAsMoment(event.target.value)})}
                    />*/}
                    <FontAwesomeIcon id="dateOfSowingLabel" size="1x" icon={faInfoCircle} />
                    &nbsp;&nbsp;
                    <DatePicker
                            name="sowingDate"
                            dateFormat="dd/MM/yyyy"
                            onChange={(val)=>this.setState({sowingDateAsMoment: moment(val)})}
                            selected={this.state.sowingDateAsMoment.toDate()}
                    />
                  </label>
                  <UncontrolledTooltip placement="bottom" target="dateOfSowingLabel">
                    {contentHelper.growthStageDesc(this.state.lang)}
                  </UncontrolledTooltip>
                </div>

                <div className="form-row" style={{display: this.state.growthStageEstimatedBySowingDate?"none":"block"}}>
                  <label>
                    {contentHelper.plantGrowthStageAtLabel(this.state.lang)}
                    {/*}<input name="growingStageDate"
                            type="text"
                            style={{textAlign: "center"}}
                            defaultValue={this.state.growthStageDateAsMoment.format('DD/MM/YYYY')}
                            onChange={(event)=>this.setState({growthStageDateAsMoment: getDateAsMoment(event.target.value),
                                                                fictiveSowingDate: shiftHelper.getFictiveSowingDate(getDateAsMoment(event.target.value),this.state.growingStage)})}
                    />*/}
                    <FontAwesomeIcon id="growingStageLabel" size="1x" icon={faInfoCircle} />
                    &nbsp;&nbsp;
                    <DatePicker
                            name="growingStageDate"
                            dateFormat="dd/MM/yyyy"
                            onChange={(val)=>this.setState({growthStageDateAsMoment: moment(val), fictiveSowingDate: shiftHelper.getFictiveSowingDate(moment(val),this.state.growingStage)})}
                            selected={this.state.growthStageDateAsMoment.toDate()}
                    />
                  </label>
                  <UncontrolledTooltip placement="bottom" target="growingStageLabel">
                    {contentHelper.growthStageDesc(this.state.lang)}
                  </UncontrolledTooltip>
                </div>
                <div className="form-row" style={{display: this.state.growthStageEstimatedBySowingDate?"none":"block"}}>
                  <label>
                    <span></span>
                    <FontAwesomeIcon id="growingStageLabel2" size="1x" icon={faInfoCircle} />
                    &nbsp;&nbsp;
                    <Slider min={25} max={75}
                              value={this.state.growingStage}
                              onChange={(value)=>{this.setState({growingStage: value,
                                                                  fictiveSowingDate: shiftHelper.getFictiveSowingDate(this.state.growthStageDateAsMoment,value)});} }
                              style={{display: "inline-block", width: "215px", padding: "5px", margin: 0}}
                    />
                    &nbsp;&nbsp; ({this.state.growingStage})
                  </label>
                  <UncontrolledTooltip placement="bottom" target="growingStageLabel2">
                    {contentHelper.growthStageDesc(this.state.lang)}
                  </UncontrolledTooltip>
                </div>

                <div className="form-row">
                  <label>
                    {contentHelper.lastFungicideSprayDateLabel(this.state.lang)}
                    {/*}<input name="lastFungicideSprayDate"
                            type="text"
                            style={{textAlign: "center"}}
                            defaultValue={this.state.lastFungicideSprayDate}
                            onChange={(event)=>this.setState({lastFungicideSprayDate: event.target.value})}
                    />*/}
                    <FontAwesomeIcon id="lastFungicideSprayDateLabel" size="1x" icon={faInfoCircle} />
                    &nbsp;&nbsp;
                    <DatePicker
                            name="lastFungicideSprayDate"
                            dateFormat="dd/MM/yyyy"
                            onChange={(val) =>
                              this.setState({lastFungicideSprayDate: moment(val).format('DD/MM/YYYY')})}
                            onChangeRaw={(event) =>
                              this.setState({lastFungicideSprayDateString : event.target.value})}
                            onBlur={event => {
                              if(!moment(event.target.value,'DD/MM/YYYY',true).isValid()){
                                this.setState({ lastFungicideSprayDate: '' });
                                this.setState({ lastFungicideSprayDateString: 'NONE' });
                              }
                            }}
                            onCalendarOpen={event => {
                              if(this.state.lastFungicideSprayDateString === 'NONE'){
                                this.setState({ lastFungicideSprayDateString: '' })
                              }
                            }}
                            autoComplete="off"
                            selected={getStringAsMoment(this.state.lastFungicideSprayDate).toDate()}
                            value={
                              this.state.lastFungicideSprayDateString === 'NONE' ?
                                contentHelper.lastFungicideSprayDateNoValue(this.state.lang):
                                this.state.lastFungicideSprayDateString
                            }
                    />
                  </label>
                  <UncontrolledTooltip placement="bottom" target="lastFungicideSprayDateLabel">
                    {contentHelper.fungicideDesc(this.state.lang)}
                  </UncontrolledTooltip>
                </div>

                {/* <div className="form-row"  >
                  <label id="cultivarSusceptibilityRankLabel">
                    {contentHelper.cultivarSusceptibilityRankLabel(this.state.lang)}
                    <Slider min={1} max={9}
                              disabled={true}
                              value={this.state.cultivarSusceptibilityRank}
                              onChange={(value)=>{this.setState({cultivarSusceptibilityRank: value});} }
                              style={{display: "inline-block", width: "215px", padding: "5px", margin: 0}}
                    />
                    &nbsp;&nbsp; ({this.state.cultivarSusceptibilityRank})
                  </label>
                  <UncontrolledTooltip placement="bottom" target="cultivarSusceptibilityRankLabel">
                    {contentHelper.cultivarDesc(this.state.lang)}
                  </UncontrolledTooltip>
                </div> */ }

                <div className="form-row" >
                  <label>
                    {contentHelper.cultivarLabel(this.state.lang)}
                    <FontAwesomeIcon id="cultivarLabel" size="1x" icon={faInfoCircle} />
                    &nbsp;&nbsp;
                    <select value={this.state.cultivar} name="cultivar" size="1" onChange={(event)=>this.setState({cultivar: event.target.value, cultivarSusceptibilityRank: this.getSusceptibilityRankByCultivar(event.target.value)})}>
                        {this.getCultivarsOptions()}
                    </select>
                  </label>
                  <UncontrolledTooltip placement="bottom" target="cultivarLabel">
                    {contentHelper.cultivarDesc(this.state.lang)}
                  </UncontrolledTooltip>
                </div>

                <Button disabled size="xs" style={{backgroundColor: "rgba(0,0,0,0)", padding: "0px"}} active></Button>

              </form>

              {/* <hr/> */}

              <LoadingOverlay styles={{ spinner: (base) => ({...base,width: '200px','& svg circle': {stroke: 'rgba(0,0,255,0.5)'}})}} active={this.state.loading} spinner text='' >
              </LoadingOverlay>

              <Histogram
                  mobileLF={mobileMode}
                  chartColorFunction={colorOfBar}
                  tooltipFunction={this.tooltipOfBar}
                  chartLabel={contentHelper.needToSprayText(this.state.lang)}
                  chartHeight={plotHeight}
                  chartData={chartSeries.sprayBar} />
              <TimeSeriesChart
                  mobileLF={mobileMode}
                  chartColorFunction={colorOfTS}
                  tooltipFunction={(entry)=>{const state=entry.isSuspectTS?contentHelper.wetText(this.state.lang):contentHelper.dryText(this.state.lang);return entry.value+"C° ("+state+")";}}
                  chartLabel={contentHelper.temperaturesText(this.state.lang)}
                  chartUnit='C°'
                  chartHeight={plotHeight}
                  chartData={chartSeries.temperatures} />
              <TimeSeriesChart
                  mobileLF={mobileMode}
                  chartColorFunction={colorOfTS}
                  tooltipFunction={(entry)=>{const state=entry.isSuspectTS?contentHelper.wetText(this.state.lang):contentHelper.dryText(this.state.lang);return entry.value+"mm ("+state+")";}}
                  chartLabel={contentHelper.precipitationsText(this.state.lang)}
                  chartUnit='mm'
                  chartHeight={plotHeight}
                  chartData={chartSeries.precipitations} />
              <TimeSeriesChart
                  mobileLF={mobileMode}
                  chartColorFunction={(entry)=>{return shiftHelper.isDuringGrowingPeriod(entry.value)?"brown":"rgba(211,211,211,0.5)";}}
                  tooltipFunction={(entry)=>{return shiftHelper.isDuringGrowingPeriod(entry.value)?contentHelper.duringGrowingPeriodText(this.state.lang):contentHelper.notDuringGrowingPeriodText(this.state.lang);}}
                  chartLabel={contentHelper.growthStageText(this.state.lang)}
                  chartUnit={null}
                  chartHeight={plotHeight}
                  chartData={chartSeries.growingStage} />

              {this.getButtonsGroup()}

            </Col>
          </Row>

          </Container>

        </section>

        {this.getModals()}

        {this.getFooter()}
      </div>
    );
  }
}




//export default geolocated({
//    positionOptions:
//    {
//        enableHighAccuracy: false,
//    }
//})(App);

export default App;
